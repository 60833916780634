<template>
  <div class="container prow-40">
    <h1 class="title pt-20 pb-10">照片下载使用协议：</h1>

    <div class="txt">
      <p>亲爱的用户感谢您使用"幼享乐"。</p>
      <p>幼享乐在此郑重提醒您，在下载照片前认真阅读本使用协议：</p>
    </div>
    <div class="txt">&nbsp;</div>
    <div class="txt">
      <p>1、本平台照片影像著作权归拍摄人所有。</p>
      <p>2、照片中主体自然人的肖像权归被拍摄个人所有。</p>
      <p>3、本平台照片的下载仅提供给拍摄人或被拍摄人用于非商业使用。</p>
      <p>4、如果需要照片用于商业目的，请联系幼享乐或拍摄人及被拍摄人获得授权。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletHelp",
};
</script>

<style lang="scss" scoped>
.title,
.txt {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
</style>
